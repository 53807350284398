import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    components: {
      hero: () => import(/* webpackChunkName: "hero" */ '@/views/Hero.vue')
    }
  },
  {
    path: '/work',
    components: {
      everything: () => import(/* webpackChunkName: "work" */ '@/views/Work.vue')
    }
  },
  {
    path: '/about',
    components: {
      everything: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
    }
  },
  {
      path: '/:catchAll(.*)',
      name: 'error',
      components: {
        everything: () => import(/* webpackChunkName: "404" */ '@/views/FourOhFour.vue')
      }
  }
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
});

export default router;
