<template>
    <nav>
        <ul :class="{ 'nav-open': isNavOpen === true }">
            <router-link v-if="route.path !== '/'"
                         custom
                         to="/"
                         v-slot="{href, isActive, navigate}"
            >
                <li :class="{'router-link-active': isActive}">
                    <a :href="href" @click="navigate">Home</a>
                </li>
            </router-link>
            <template v-for="(item) in navLinks">
                <router-link v-if="item.path"
                             :key="item.label"
                             custom
                             :to="item.path"
                             v-slot="{href, isActive, navigate}"
                >
                    <li :class="{'router-link-active': isActive}">
                        <a :href="href" @click="navigate">{{ item.label }}</a>
                    </li>
                </router-link>
                <li v-else :key="item.label">
                    <a :href="item.link"
                       target="_blank"
                       :title="`This link opens a new tab at ${ item.label }`"
                    >
                        {{ item.label }}
                    </a>
                </li>
            </template>
        </ul>
        <div class="nav-hamburger"
             :class="{ 'close-button': isNavOpen === true }"
             @click="openNav()"
        >
        </div>
    </nav>
</template>

<script>
    import { ref, watch } from 'vue';
    import { useRoute } from 'vue-router';

    export default {
        setup() {
            const route = useRoute();
            const isNavOpen = ref(false);
            const navLinks = [
                {path: '/about', label:'About'},
                {path: '/work', label: 'Work'},
                {link: 'https://gitlab.com/dankiser', label: 'Git Lab'},
                {link: 'https://codepen.io/dankiser/', label: 'Codepen'}
            ];
            watch(() => route.path, (newVal, oldVal) => {
                if( newVal !== oldVal ) isNavOpen.value = false;
            });
            function openNav() {
                isNavOpen.value = !isNavOpen.value;
                // this.navItems = document.querySelectorAll('nav li');

                // let body = document.querySelector('body');
                // body.classList.toggle('noscroll');
                //
                // this.navItems.forEach( (el) => {
                //     el.addEventListener('click', () => this.openNav());
                // });
            }

            return {
                route,
                isNavOpen,
                navLinks,
                openNav,
            }
        }
    }
</script>

<style lang="scss">

</style>
