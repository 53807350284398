import { createStore } from 'vuex';

export default createStore({
    state() {
        return {
            isModalOpen: false,
            isNavOpen: false,
        }
    },
    mutations: {
        SET_STATE(state, payload) {
            state[payload.prop] = payload.value;
        }
    },
    getters: {
        isScrollDisabled: state => {
            return state.isModalOpen || state.isNavOpen;
        }
    },
    actions: {
    },
    modules: {
    }
})
