<template>
    <footer>
        <div>
            <p>
                &copy; {{ currentYear }} Dan Kiser<br>
                <router-link to="/" title="Go to the home page">http://kiser.codes</router-link>
            </p>
            <p>
                Made with:
                <vue-logo />
            </p>
        </div>
    </footer>
</template>

<script>
    import VueLogo from '@/assets/icons/vue-logo.svg';

    export default {
        components: {
            VueLogo,
        },
        setup() {
            const currentYear = new Date().getFullYear();

            return { currentYear }
        }
    }
</script>
