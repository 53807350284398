<template>
    <div :class="{
            'modal-open': isModalOpen,
            'noscroll': isScrollDisabled
        }"
    >
        <router-view name="hero" v-slot="routerSlot">
            <transition name="slide" :css="false">
                <component :is="routerSlot.Component"></component>
            </transition>
        </router-view>

        <app-nav></app-nav>

        <main>
            <router-view name="everything"></router-view>
        </main>

        <app-footer></app-footer>
    </div>
</template>

<script>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import Nav from "@/components/Nav.vue";
    import Footer from "@/components/Footer.vue";

    export default {
        name: "App",
        components: {
            'app-nav': Nav,
            'app-footer': Footer,
        },
        setup() {
            const store = useStore();
            const isModalOpen = computed( () => store.state.isModalOpen );
            const isScrollDisabled = computed( () => store.getters.isScrollDisabled );
            const getHeight = computed((el="") => {
                // @@TODO
                el = document.querySelector(el);
                const elPosition = el.getBoundingClientRect();
                // console.log(elPosition);
                return elPosition.bottom;
                // transform: `translateY({$elPosition.y})`
            });

            return {
                getHeight,
                isModalOpen,
                isScrollDisabled,
            }
        }
    };
</script>

<style lang="scss">
    @import 'src/scss/styles';

    .slide-leave-active,
    .slide-enter {
        margin-top: calc(-100vh + #{grid(footerHeight)} + #{grid(navHeight)}); // same height as hero
        // transform: translateY(-100vh + #{grid(footerHeight)} + #{grid(navHeight)});
        // transform: translateY(-856px);
    }

    .slide-enter-active-to {
        transform: translateY(0);
    }

    .slide-enter-active,
    .slide-leave-active {
        transition: margin-top 0.5s ease-out;
        // transition: transform 0.5s ease-out;
    }

    .fade-enter-from,
    .fade-leave-active {
        opacity: 0;
    }

    .fade-enter-active {
        opacity: 1;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease-out;
    }

    .fade-enter-active {
        transition-delay: -0.5s;
    }
</style>
